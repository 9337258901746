// import Swiper JS
// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, SliderSlide, Autoplay } from 'swiper';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

var hc_sponsors_swipers = [];

jQuery(document).ready(function ($) { 
    if (hc_sponsors_classes && Array.isArray(hc_sponsors_classes)) {
        for (let sponsor_class in hc_sponsors_classes) {
            let sponsor_data = hc_sponsors_classes[sponsor_class];
            
            let swiper_options = {
                // Optional parameters
                spaceBetween: 30,
                //slidesPerView: 1,
                centeredSlides: true,
                loop: true,                

                // If we need pagination
                pagination: {
                  el: '.swiper-pagination',
                  clickable: true,
                },

                // Navigation arrows
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
                modules: [Navigation, Pagination, Autoplay],
                // And if we need scrollbar
                /*scrollbar: {
                  el: '.swiper-scrollbar',
                }*/
            };
            
            if (sponsor_data['effect']) {
                switch (sponsor_data['effect']) {
                    case 'effect':
                        swiper_options['effect'] = 'fade';
                        break;
                    default:
                            //nothing here
                }
            }
            
            if (sponsor_data['autoplay_delay']) {
                swiper_options['autoplay'] = {
                    delay: parseInt(sponsor_data['autoplay_delay']),
                    disableOnInteraction: false
                }
            }
            
            hc_sponsors_swipers[sponsor_class] = new Swiper('.' + sponsor_class, swiper_options);
        }
    }
});